/**
 * Generated by `createschema user.User name:Text; password?:Password; isAdmin?:Checkbox; email?:Text; isEmailVerified?:Checkbox; phone?:Text; isPhoneVerified?:Checkbox; avatar?:File; meta:Json; importId:Text;`
 */
const SBBOL_IDENTITY_TYPE = 'sbbol'
const IDENTITY_TYPES = [SBBOL_IDENTITY_TYPE]

const DELETED_USER_NAME = 'Deleted User'
const TRACKING_USER_FIELDS = ['id']

module.exports = {
    DELETED_USER_NAME,
    TRACKING_USER_FIELDS,
    SBBOL_IDENTITY_TYPE,
    IDENTITY_TYPES,
}
